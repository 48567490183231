import React from 'react';
import FolderImg from 'assets/folder-fill.svg';

const FolderComponent = ({ folderName }) => {
  return (
    <div className="border p-3 main-folder">
      <div className="folder-wrap" title={folderName}>
        <img src={FolderImg} alt="Folder Img" />
        <span className="ml-3 font-size-14 font-weight-500">{folderName}</span>
      </div>
    </div>
  );
};

export default FolderComponent;
