import React, { useContext, useEffect } from 'react';
import { useParams, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { useQuery } from '@apollo/client';
import {
  GET_PROJECT_DETAILS,
  PILOT_EDITOR_INVITED_ACCEPTED,
} from 'pages/PMPortal/components/Projects/ProjectsGraphQL';
import PageLoader from 'common/PageLoader';
import AWSfolders from 'components/AWSfolders';
import { PORTALS } from 'constants/appNavigations';
import ErrorBoundary from 'common/ErrorBoundary';
import NoData from 'common/NoData';
import Header from 'pages/PMPortal/common/Header';
import { ASSET_TAB_NAMES } from 'pages/EditorPortal/constant';
import { Button, PopoverBody, UncontrolledPopover } from 'reactstrap';
import { PROJECT_TASK_SUBSCRIPTION } from 'pages/ManagementPortal/components/Projects/ProjectsGraphQL';
import ProjectDetail from 'pages/ManagementPortal/components/Projects/components/ProjectDetail';
import { BRONZE } from 'constants/appConstants';
import ProjectDetailHeader from './components/ProjectDetailHeader';
import { ROLES } from 'constants/role';
import { authContext } from 'contexts/AuthContext';
import { GET_ALL_CREDENTIALS_BY_PROJECT } from 'pages/Vidpitch/VidPitchGraphQL';

const VideoAssetsFolders = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  let { projectId } = useParams();
  const { auth } = useContext(authContext);

  const { activeTabStatus } = useSelector((state) => state.pmPortal);

  projectId = parseInt(projectId, 10);

  const { data: credientialsData } = useQuery(GET_ALL_CREDENTIALS_BY_PROJECT, {
    variables: { projectId },
  });

  const {
    PM_PORTAL: { slug },
  } = PORTALS;
  const { data, loading, error, subscribeToMore, refetch } = useQuery(
    GET_PROJECT_DETAILS,
    {
      variables: {
        projectId,
      },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (location?.state?.clearTab) {
      dispatch({ type: 'ACTIVE_TAB_STATUS', payload: 0 });
    }
    if (!loading && data?.getProjectDetails) {
      if (data?.getProjectDetails?.planType !== BRONZE) {
        dispatch({ type: 'ACTIVE_TAB_STATUS', payload: 1 });
      }
    }
  }, [loading, data]);

  const onChangeActiveButton = (i) => {
    dispatch({ type: 'ACTIVE_TAB_STATUS', payload: i });
  };

  useEffect(() => {
    const subscribe = subscribeToMore({
      document: PILOT_EDITOR_INVITED_ACCEPTED,
      updateQuery: (prev, { subscriptionData }) => {
        if (subscriptionData?.data) {
          refetch();
        }

        return prev;
      },
    });

    return () => subscribe();
  }, []);

  useEffect(() => {
    const subscribe = subscribeToMore({
      document: PROJECT_TASK_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (data?.getProjectDetails?.planType === BRONZE) {
          if (subscriptionData?.data?.bronzeVideoStatus?.data) {
            const {
              data: {
                bronzeVideoStatus: {
                  data: { status, projectId: subscribedProjectId },
                },
              },
            } = subscriptionData;
            if (status && parseInt(subscribedProjectId, 10) === projectId) {
              refetch();
            }
          }
        }
        return prev;
      },
    });
    return () => subscribe();
  }, [data]);

  if (loading) return <PageLoader />;
  if (error) return <NoData customMessage={error.message} />;

  const renderTabButtons = (planType) => {
    return ASSET_TAB_NAMES.map((tabName, i) => {
      return (
        <div key={tabName} className="position-relative">
          <Button
            outline
            onClick={() => onChangeActiveButton(i)}
            disabled={tabName === ASSET_TAB_NAMES[0] && planType !== 'BRONZE'}
            className={`tab-button-editor ${
              i === activeTabStatus ? 'isactive' : ''
            }`}
          >
            {tabName}
          </Button>
        </div>
      );
    });
  };

  if (data && data.getProjectDetails) {
    const {
      getProjectDetails: {
        projectName,
        projectAsanaLink,
        slackLink,
        startDate,
        timeElapsed,
        projectEditor,
        projectManager,
        projectPilot,
        isPilotInviteAccepted,
        isEditorInviteAccepted,
        dataSource,
        isCompleted,
        projectReviews,
        planType,
        status,
        crexiLink,
      },
    } = data;

    return (
      <>
        <div className="video-review ">
          <div className="video-review-head">
            <ProjectDetailHeader
              projectId={projectId}
              projectName={projectName}
              projectAsanaLink={projectAsanaLink}
              slackLink={slackLink}
              startDate={startDate}
              timeElapsed={timeElapsed}
              projectEditor={projectEditor}
              projectPilot={projectPilot}
              projectManager={projectManager}
              isPilotInviteAccepted={isPilotInviteAccepted}
              isEditorInviteAccepted={isEditorInviteAccepted}
              dataSource={dataSource}
              isCompleted={isCompleted}
              projectReviews={projectReviews}
              refetchProjectDetails={refetch}
              planType={planType}
              status={status}
              crexiLink={crexiLink}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">{renderTabButtons(planType)}</div>
            <div>
              {[ROLES.VIDEOMS_PM, ROLES.ADMIN].includes(auth?.data?.role) && (
                <>
                  <Button
                    color="primary"
                    // className="px-3 font-size-12 font-weight-bold  btn-sm"
                    id="credintialsPopover"
                    type="button"
                    outline
                    size="sm"
                  >
                    Show credientials
                  </Button>
                  <UncontrolledPopover
                    placement="bottom"
                    target="credintialsPopover"
                    className="credintials-popover"
                    trigger="focus"
                  >
                    <PopoverBody className="p-0">
                      <div className="react-bootstrap-table">
                        <table className="p-0 table project-table bordered mb-0">
                          <thead>
                            <tr>
                              <th>Email</th>
                              <th>Password</th>
                              <th>Role</th>
                            </tr>
                          </thead>
                          <tbody>
                            {credientialsData?.getAllCredentialsByProject
                              .length ? (
                              credientialsData?.getAllCredentialsByProject?.map(
                                (item) => (
                                  <tr key={item.email}>
                                    <td>{item.email}</td>
                                    <td>{item.password}</td>
                                    <td>{item.role}</td>
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td colSpan={3} className="text-center">
                                  <b>No data available</b>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </PopoverBody>
                  </UncontrolledPopover>
                </>
              )}
            </div>
          </div>
          <ErrorBoundary>
            {activeTabStatus === 0 ? (
              <ProjectDetail pmEditorSelectedPlan={planType} />
            ) : (
              <AWSfolders
                projectId={projectId}
                projectName={projectName}
                portalSlug={slug}
              />
            )}
          </ErrorBoundary>
        </div>
      </>
    );
  }

  return (
    <>
      <Header />
      <NoData customMessage="Requested project does not exist." />
    </>
  );
};
export default VideoAssetsFolders;
