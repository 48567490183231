import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Input } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';

import Header from 'pages/ManagementPortal/common/Header';
import ErrorBoundary from 'common/ErrorBoundary';
import { addActiveProjectPageInfo } from 'pages/ManagementPortal/ManagementPortalActions';
import ProjectTableProvider from './components/ProjectTableProvider';
import { authContext } from 'contexts/AuthContext';
import { ROLES } from 'constants/role';
import PageLoader from 'common/PageLoader';
import { GET_AIRTABLE_IFRAME } from '../../ProjectsGraphQL';

const FILTER = {
  trackStatus: 'ACTIVE',
  limit: 10,
  offset: 0,
  page: 1,
};

const ActiveProjects = () => {
  const dispatch = useDispatch();
  const {
    activeProjectInfo: {
      previousPageInfo: {
        page,
        offset,
        previousRoutes: { searchText },
      },
    },
  } = useSelector((state) => state.managementPortal);

  const {
    auth: {
      data: { role },
    },
  } = useContext(authContext);

  const [filters, setFilters] = useState(FILTER);

  const { loading, error, data } = useQuery(GET_AIRTABLE_IFRAME);

  useEffect(() => {
    setFilters({ ...filters, offset, page });
  }, [page, offset]);

  if (loading) return <PageLoader />;
  if (error) return <p>Error: {error.message}</p>;

  const handleSearchProject = (e) => {
    dispatch(
      addActiveProjectPageInfo({
        previousPageInfo: {
          previousRoutes: {
            path: '',
            searchText: e.target.value,
          },
          page: 1,
          offset: 0,
        },
      })
    );
  };

  const AirtableEmbed = ({ src }) => (
    <iframe
      className="airtable-embed"
      src={src}
      width="100%"
      title="airtable-embed"
    />
  );

  const ProjectHeader = (searchText, handleSearchProject) => (
    <Row className="align-items-center">
      <Col md="6">
        <h6 className="my-3">Active Projects</h6>
      </Col>
      <Col md="4" className="ml-auto">
        <div className="my-3">
          <Input
            type="search"
            name="searchText"
            id="searchText"
            placeholder="Search"
            value={searchText}
            onChange={handleSearchProject}
          />
        </div>
      </Col>
    </Row>
  );

  const ProjectTableSection = ({ filters, searchText, setFilters }) => (
    <ErrorBoundary>
      <ProjectTableProvider
        filters={filters}
        searchText={searchText}
        setFilters={setFilters}
      />
    </ErrorBoundary>
  );

  const isAirtableViewable = [
    ROLES.USER,
    ROLES.VIDEOMS_PM,
    ROLES.ADMIN,
  ].includes(role);

  return (
    <>
      <Header heading="Projects" />

      <div>
        {isAirtableViewable ? (
          <AirtableEmbed src={data?.airtableIframe?.iframe} />
        ) : (
          <>
            {ProjectHeader(searchText, handleSearchProject)}
            <ProjectTableSection
              filters={filters}
              searchText={searchText}
              setFilters={setFilters}
            />
          </>
        )}
      </div>
    </>
  );
};
export default ActiveProjects;
