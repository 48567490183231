import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import ActiveProjects from './components/ActiveProjects';
import Completed from './components/Completed';
import NeedMyAttention from './components/NeedMyAttention';
import ProjectDetail from './components/ProjectDetail';
import { authContext } from 'contexts/AuthContext';
import { ROLES } from 'constants/role';

const Projects = ({ component, ...rest }) => {
  const { path } = rest.match;
  const {
    auth: {
      data: { role },
    },
  } = useContext(authContext);

  const isAirtableViewable = [
    ROLES.USER,
    ROLES.VIDEOMS_PM,
    ROLES.ADMIN,
  ].includes(role);

  return (
    <Row>
      <Col className={`${isAirtableViewable ? 'px-0' : ''}`}>
        <Switch>
          <Route
            exact
            path={`${path}/active-projects`}
            component={ActiveProjects}
          />

          <Route
            path={`${path}/active-projects/project-detail/:projectId/:planName`}
            component={ProjectDetail}
          />

          <Route
            path={`${path}/need-my-attention/project-detail/:projectId/:planName`}
            component={ProjectDetail}
          />

          <Route
            path={`${path}/completed/project-detail/:projectId/:planName`}
            component={ProjectDetail}
          />

          <Route
            exact
            path={`${path}/need-my-attention`}
            component={NeedMyAttention}
          />

          <Route exact path={`${path}/completed`} component={Completed} />
        </Switch>
      </Col>
    </Row>
  );
};
export default Projects;
