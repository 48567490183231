import React, { useContext } from 'react';
import VideoMSLogo from 'common/VideoMSLogo';
import CrexiBrandLogo from 'common/CrexiBrandLogo';
import ManagementNavigation from './components/ManagementNavigation';
import { authContext } from 'contexts/AuthContext';
import { ROLES } from 'constants/role';

const Sidebar = () => {
  const {
    auth: {
      data: { role },
    },
  } = useContext(authContext);
  return (
    <div className="sidebar-quad px-3">
      <div className="d-flex justify-content-between align-items-center logo-height">
        <VideoMSLogo />
        <CrexiBrandLogo />
      </div>
      <div className="portal-name">
        <span>MANAGEMENT PORTAL</span>
      </div>
      <div>
        {role === ROLES.USER ||
        role === ROLES.VIDEOMS_PM ||
        role === ROLES.ADMIN ? null : (
          <ManagementNavigation />
        )}
      </div>
    </div>
  );
};
export default Sidebar;
