import { gql } from '@apollo/client';

export const GET_PROJECTS = gql`
  query getProjects($trackStatus: String!, $limit: Int!, $offset: Int!) {
    getProjects(trackStatus: $trackStatus, limit: $limit, offset: $offset)
  }
`;

export const GET_PROJECT_DETAIL = gql`
  query getTaskDetails($projectId: Int!, $planName: String!) {
    getTaskDetails(projectId: $projectId, planName: $planName)
  }
`;

export const GET_ATTENTION_PROJECT = gql`
  query getAttentionProjects($limit: Int!, $offset: Int!) {
    getAttentionProjects(limit: $limit, offset: $offset)
  }
`;

export const GET_NOTIFICATIONS = gql`
  query getNotifications($limit: Int, $offset: Int) {
    getNotifications(limit: $limit, offset: $offset)
  }
`;
export const PROJECT_SEARCH = gql`
  query projectSearch(
    $searchText: String!
    $trackStatus: String!
    $limit: Int!
    $offset: Int!
  ) {
    projectSearch(
      searchText: $searchText
      trackStatus: $trackStatus
      limit: $limit
      offset: $offset
    )
  }
`;

export const UPDATE_NOTIFICATION_STATUS = gql`
  mutation updateNotificationStatus(
    $actionValue: Boolean!
    $status: String!
    $id: Int!
  ) {
    updateNotificationStatus(
      actionValue: $actionValue
      status: $status
      id: $id
    )
  }
`;

export const TASK_NOTIFICATION_SUBSCRIPTION = gql`
  subscription taskNotification {
    taskNotification
  }
`;

export const TASK_UPDATE_SUBSCRIPTION = gql`
  subscription taskUpdated {
    taskUpdated
  }
`;

export const NOTIFICATION_ACTION_SUBSCRIPTION = gql`
  subscription notificationAction {
    notificationAction
  }
`;

export const LIST_ASANA_TASK_ACTIVITIES = gql`
  query listAsanaTaskActivities($taskId: String!, $planName: String!) {
    listAsanaTaskActivities(taskId: $taskId, planName: $planName)
  }
`;

export const SYNC_MANAGEMENT_PORTAL_TASKS = gql`
  mutation syncManagementProjectTasks(
    $asanaProjectId: String
    $isNewTemplate: Boolean!
    $planType: String!
    $sectionName: String!
    $projectId: Int
  ) {
    syncManagementProjectTasks(
      asanaProjectId: $asanaProjectId
      isNewTemplate: $isNewTemplate
      planType: $planType
      sectionName: $sectionName
      projectId: $projectId
    )
  }
`;

export const UPDATE_BRONZE_PROJECT_TASK_STATUS = gql`
  mutation updateBronzeProjectTaskStatus(
    $crexiTaskStatus: String!
    $portalTaskId: String!
  ) {
    updateBronzeProjectTaskStatus(
      crexiTaskStatus: $crexiTaskStatus
      portalTaskId: $portalTaskId
    )
  }
`;

export const PROJECT_TASK_SUBSCRIPTION = gql`
  subscription bronzeVideoStatus {
    bronzeVideoStatus
  }
`;

export const GET_AIRTABLE_IFRAME = gql`
  query {
    airtableIframe
  }
`;
