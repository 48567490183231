export const ASSET_LISTING_IMAGE = 'Image';
export const ASSET_LISTING_VIDEO = 'Video';
export const ASSET_LISTING_CUSTOM = 'Custom';
export const ASSET_LISTING_VIDEOS_IMAGES = ['Image', 'Video'];

export const PROJECT_DETAIL = 'PROJECT_DETAIL';
export const VIDEOS = 'VIDEOS';
export const PHOTOS = 'PHOTOS';
export const CUSTOM_SHOTS = 'CUSTOM_SHOTS';
export const FOLDER_TYPES = {
  DEFAULT: 'Default',
  CUSTOM: 'Custom',
  VIDEO: 'Video',
};
export const pilotRegistrationInitialValues = {
  photo: '',
  lastName: '',
  phone: '',
  address: {
    state: '',
    zip: '',
    address: '',
  },
  coverageArea: '',
  password: '',
  confirmPassword: '',
  faaLicenseNumber: '',
  faaExpireDate: '',
  insurancePolicyNumber: '',
  insuranceExpireDate: '',
  dronePlatform: '',
  camera: '',
  offerThermalDrone: true,
  offerInteriorDronePhotos: true,
  offerMatterPortOrOtherTours: true,
  offerProfessionalInteriorDronePhotos: true,
};

export const coverageAreaOptions = [
  '25miles',
  '50miles',
  '75miles',
  '100miles',
  '200miles',
];

export const dronePlatformOptions = [
  'Phantom 2',
  'Phantom 3',
  'Phantom 4 Pro',
  'Mavic Pro',
  'Mavic Air 2',
  'Mavic 2 Pro',
  'Mavic 3',
  'DJI Inspire 2',
  'Autel EVO II',
  'Autel EVO II Pro',
  'DJI Air 2S',
  'Other',
];

export const cameraOptions = ['12 MP', '14 MP', '20 MP', '30 MP', '48 MP'];
export const PILOT_PROJECT_ACTIONS = {
  Accept: 'accepted',
  Decline: 'declined',
};
