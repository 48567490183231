import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Button,
} from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import { PROJECT_INSTRUCTIONS } from 'constants/appConstants';

const PopupForm = ({ isOpen, toggle, handleSubmit, setCancel }) => {
  const onCancel = () => {
    setCancel(true);
    toggle();
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>Project Instructions</ModalHeader>
      <ModalBody>
        <Formik
          initialValues={{
            mapping_question_1: '',
            mapping_question_2: '',
            mapping_question_3: '',
          }}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormGroup>
                <Label for=" mapping_question_1">
                  {PROJECT_INSTRUCTIONS.QUESTION1}
                </Label>
                <Field
                  className="form-control"
                  type="textarea"
                  name="mapping_question_1"
                  id="mapping_question_1"
                  component="textarea"
                />
              </FormGroup>
              <FormGroup>
                <Label for="mapping_question_2">
                  {PROJECT_INSTRUCTIONS.QUESTION2}
                </Label>
                <Field
                  className="form-control"
                  type="textarea"
                  name="mapping_question_2"
                  id="mapping_question_2"
                  component="textarea"
                />
              </FormGroup>
              <FormGroup>
                <Label for="mapping_question_3">
                  {PROJECT_INSTRUCTIONS.QUESTION3}
                </Label>
                <Field
                  className="form-control"
                  type="textarea"
                  name="mapping_question_3"
                  id="mapping_question_3"
                  component="textarea"
                />
              </FormGroup>
              <ModalFooter className="px-0 pb-0">
                <Button
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  size="sm"
                >
                  Add Instructions
                </Button>
                <Button color="secondary" onClick={() => onCancel()} size="sm">
                  Close
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default PopupForm;
