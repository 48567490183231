import React, { useContext, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import {
  Col,
  Row,
  Card,
  CardBody,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
  DropdownToggle,
  Button,
  Modal,
  ModalBody,
  UncontrolledPopover,
  PopoverBody,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faHome,
  faCalendar,
  faTag,
  faCaretUp,
} from '@fortawesome/free-solid-svg-icons';
import ImageGallery from 'react-image-gallery';
import axios from 'axios';

import { ACTIVE_PROJECT_COLUMN } from 'pages/ManagementPortal/constant';
import { BRONZE, NOT_SPECIFIED, SLACK_LINK } from 'constants/appConstants';
import {
  blobToDownloadLink,
  dateTimeFormatter,
  numberWithCommas,
} from 'utils/helper';
import slackIMG from 'assets/slack.png';
import asanaIMG from 'assets/asana.png';
import hubspotIcon from 'assets/hubspot-icon.png';
import linkImage from 'assets/link.svg';
import closeImage from 'assets/close-icon.svg';
import NoData from 'common/NoData';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  GET_ALL_CREDENTIALS_BY_PROJECT,
  GET_CUSTOM_ASSET_IMAGES,
} from 'pages/Vidpitch/VidPitchGraphQL';
import { CUSTOM_IMAGE_UPLOAD_ENUM } from 'pages/CrexiBronze/constant';
import { ROLES } from 'constants/role';
import { authContext } from 'contexts/AuthContext';
import { toast } from 'react-toastify';

const AssetDetails = ({ detail }) => {
  const {
    broker,
    crexiUrl,
    askingPrice,
    listingDate,
    propertyType,
    thumbnailUrl,
    projectAddress,
    projectAsanaLink,
    slackLink,
    hubspotLink,
    plan,
    projectUuid,
    projectName,
    id,
  } = detail;

  const { auth } = useContext(authContext);
  const [dropdownOpen, setOpen] = useState(false);
  const [isGalleryModal, setGalleryModel] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const downloadChapterImagesZip = async () => {
    setLoading(true);
    const data = {
      access_token: auth?.data?.token,
      project_uuid: projectUuid,
      project_name: projectName,
    };
    const config = {
      responseType: 'blob',
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_ENDPOINT}api/download-bronze-images`,
        data,
        config
      );
      blobToDownloadLink(response, `BronzeImages-${projectName}.zip`);
    } catch (error) {
      if (
        error?.response?.headers['content-type']?.includes('application/json')
      ) {
        const jsonError = JSON.parse(await error?.response?.data?.text());
        toast.error(jsonError?.error);
      }
    } finally {
      setLoading(false);
    }
  };

  const [getCustomAssetImages, { data }] = useLazyQuery(
    GET_CUSTOM_ASSET_IMAGES
  );

  const { data: credientialsData } = useQuery(GET_ALL_CREDENTIALS_BY_PROJECT, {
    variables: { projectId: id },
  });

  const customImges = data?.getCustomAssetImages
    ? data?.getCustomAssetImages?.map((value) => ({
        original: value?.s3ImageLink,
        thumbnail: value?.s3ImageLink,
      }))
    : [];

  const toggleBrokerDropdown = () => setOpen(!dropdownOpen);

  const renderBrokerCount = () => {
    return (
      <div className="d-flex align-items-center border-left-3">
        {broker !== NOT_SPECIFIED && (
          <ButtonDropdown
            className="bg-white"
            isOpen={dropdownOpen}
            toggle={toggleBrokerDropdown}
          >
            <DropdownToggle className="bg-white border-0 text-dark count-btn">
              + {broker.length - 1}
            </DropdownToggle>
            <DropdownMenu className="border-radius-8px border-0 shadow-lg">
              {broker.map((row, index) => {
                if (index > 0) {
                  return (
                    <DropdownItem
                      key={`${row.brokerName}_${index + 1}`}
                      className="py-0 px-1"
                    >
                      <div className="d-flex align-items-center flex-fill p-2">
                        <img
                          src={
                            row.imageLink || 'https://via.placeholder.com/40'
                          }
                          alt="Broker"
                          className="rounded-circle h-40"
                        />
                        <div className="ml-2 ">
                          <h6 className="mb-1 font-size-14">
                            {row.brokerName}
                          </h6>
                          <p className="m-0 font-size-12">BROKER</p>
                        </div>
                      </div>
                    </DropdownItem>
                  );
                }
                return null;
              })}
            </DropdownMenu>
          </ButtonDropdown>
        )}
      </div>
    );
  };

  const toggleGalleryModal = () => {
    setGalleryModel((pre) => {
      if (!pre) {
        getCustomAssetImages({
          variables: {
            fileType: '',
            offset: 0,
            limit: 500,
            uuid: projectUuid,
            packageType: CUSTOM_IMAGE_UPLOAD_ENUM.BRONZE,
          },
          fetchPolicy: 'network-only',
        });
        return !pre;
      }
      return !pre;
    });
  };

  const renderBrokerNames = () => {
    return (
      <>
        <CardBody className="d-flex p-1">
          <div className="d-flex align-items-center flex-fill p-2">
            <img
              src={broker[0].imageLink || 'https://via.placeholder.com/40'}
              alt="Broker"
              className="rounded-circle h-40"
            />
            <div className="ml-2 ">
              <h6
                className="mb-1 broker-name-mngmnt"
                title={broker[0].brokerName || 'Not Specified'}
              >
                {broker[0].brokerName || 'Not Specified'}
              </h6>
              <p className="m-0 font-size-12">BROKER</p>
            </div>
          </div>
          {broker.length > 1 ? renderBrokerCount() : ''}
        </CardBody>
      </>
    );
  };

  const viewGallery = () => {
    return (
      <Modal
        isOpen={isGalleryModal}
        toggle={toggleGalleryModal}
        className="modal-dialog-centered modal-xl"
      >
        <ModalBody className="position-relative">
          <Button
            color="link"
            className="position-absolute close-btn"
            onClick={toggleGalleryModal}
          >
            <img src={closeImage} alt="Close Icon" />
          </Button>
          {customImges.length ? (
            <ImageGallery
              items={customImges}
              showFullscreenButton={false}
              lazyLoad
            />
          ) : (
            <NoData customMessage="No images found for project!" />
          )}
        </ModalBody>
      </Modal>
    );
  };

  const activeProjectExpandRow = () => {
    return (
      <div
        className="project-collapse-detail"
        style={{
          backgroundImage:
            'linear-gradient(to right, rgba(255, 0, 0, 0), rgb(86 83 116))',
        }}
      >
        <div className="d-flex justify-content-between">
          <div>
            {broker !== NOT_SPECIFIED && thumbnailUrl !== null && (
              <img
                src={thumbnailUrl}
                alt="broker-img"
                style={{
                  width: '350px',
                  height: '200px',
                  objectFit: 'cover',
                }}
              />
            )}
          </div>
          <div>
            <Row className="m-0 py-4">
              <Col md="auto" className="px-0">
                <Card className="border-radius-8px">
                  {broker.length > 0 && renderBrokerNames()}
                </Card>
                <ul className="list-inline mb-1 mt-2">
                  {plan !== BRONZE && (
                    <>
                      <li className="list-inline-item">
                        <a
                          href={projectAsanaLink || 'https://asana.com/'}
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img
                            src={asanaIMG}
                            alt={asanaIMG}
                            className="mr-1"
                            height="25"
                            width="25"
                          />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          href={hubspotLink || 'https://hubSpot.com/'}
                          rel="noreferrer"
                          target="_blank"
                          className="mb-0"
                        >
                          <img
                            src={hubspotIcon}
                            alt={hubspotIcon}
                            className="mr-1"
                            height="25"
                            width="25"
                          />
                        </a>
                      </li>
                    </>
                  )}
                  {![ROLES.BROKER, ROLES.USER].includes(auth?.data?.role) && (
                    <li className="list-inline-item">
                      <a
                        href={slackLink || SLACK_LINK}
                        rel="noreferrer"
                        target="_blank"
                        className="mb-0"
                        title="Slack Message"
                      >
                        <img
                          src={slackIMG}
                          alt={slackIMG}
                          className="mr-2"
                          height="25"
                          width="25"
                        />
                      </a>
                    </li>
                  )}
                </ul>
              </Col>
              {/* <Col md="auto" className="my-auto"></Col> */}
              <Col md="auto" className="ml-auto">
                <Card className="border-radius-8px p-1">
                  <CardBody className="p-2">
                    <Row>
                      <Col md="auto">
                        <div className="ml-2 d-flex align-items-center">
                          <FontAwesomeIcon
                            icon={faHome}
                            className="active-project-icon"
                            style={{ padding: '5px' }}
                          />
                          <div>
                            <p className="m-0 font-size-12">PROPERTY TYPE</p>
                            <h6
                              className="mb-1 font-size-14 property-type"
                              title={propertyType}
                            >
                              {!propertyType ? 'Not Specified' : propertyType}
                            </h6>
                          </div>
                        </div>
                      </Col>
                      <Col md="auto" className="border-left border-right">
                        <div className="ml-2 d-flex align-items-center">
                          <FontAwesomeIcon
                            icon={faCalendar}
                            className="active-project-icon"
                          />
                          <div>
                            <p className="m-0 font-size-12">LISTING DATE</p>
                            <h6 className="mb-1 font-size-14">
                              {!listingDate
                                ? 'Not Specified'
                                : dateTimeFormatter(listingDate)}
                            </h6>
                          </div>
                        </div>
                      </Col>
                      <Col md="auto">
                        <div className="ml-2 d-flex align-items-center">
                          <FontAwesomeIcon
                            icon={faTag}
                            className="active-project-icon"
                          />
                          <div>
                            <p className="m-0 font-size-12">ASKING PRICE</p>
                            <h6 className="mb-1 font-size-14">
                              {askingPrice !== NOT_SPECIFIED
                                ? `$${numberWithCommas(askingPrice)}`
                                : 'Not Specified'}
                            </h6>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={7}>
                <div className="px-0">
                  {crexiUrl !== null && (
                    <>
                      <img src={linkImage} alt="link" />{' '}
                      <a href={crexiUrl} target="_blank" rel="noreferrer">
                        <span>{crexiUrl}</span>
                      </a>
                    </>
                  )}
                </div>
              </Col>
              <Col md={5}>
                <div className="pr-3 text-right">
                  <span>{projectAddress}</span>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  };

  const [isDetailExpand, setDetailExpand] = useState(false);
  return (
    <Card
      className="active-project-expandable border-0 shadow-sm"
      style={{ background: '#F0F0F5' }}
    >
      <CardBody className="p-0">
        <BootstrapTable
          keyField="projectName"
          data={[detail]}
          columns={ACTIVE_PROJECT_COLUMN}
          bordered={false}
        />
        <div className="d-flex justify-content-between">
          <div>
            <Button
              color="link"
              size="sm"
              onClick={() => setDetailExpand(!isDetailExpand)}
              className="px-3 font-size-12 font-weight-bold"
            >
              View more details{' '}
              {isDetailExpand ? (
                <FontAwesomeIcon icon={faCaretUp} />
              ) : (
                <FontAwesomeIcon icon={faCaretDown} />
              )}
            </Button>
          </div>
          {plan === BRONZE && auth?.data?.role === ROLES.USER ? (
            <div>
              <Button
                color="link"
                className="px-3 font-size-12 font-weight-bold  btn-sm"
                onClick={toggleGalleryModal}
              >
                View
              </Button>
              <Button
                color="link"
                className="px-3 font-size-12 font-weight-bold  btn-sm"
                onClick={downloadChapterImagesZip}
                disabled={isLoading}
              >
                {`${isLoading ? 'Downloading' : 'Download'} Images`}
              </Button>
            </div>
          ) : null}

          {[ROLES.VIDEOMS_PM, ROLES.ADMIN].includes(auth?.data?.role) && (
            <>
              <Button
                color="link"
                className="px-3 font-size-12 font-weight-bold  btn-sm"
                id="credintialsPopover"
                type="button"
              >
                Show credientials
              </Button>
              <UncontrolledPopover
                placement="bottom"
                target="credintialsPopover"
                className="credintials-popover"
                trigger="focus"
              >
                <PopoverBody className="p-0">
                  <div className="react-bootstrap-table">
                    <table className="p-0 table project-table bordered mb-0">
                      <thead>
                        <tr>
                          <th>Email</th>
                          <th>Password</th>
                          <th>Role</th>
                        </tr>
                      </thead>
                      <tbody>
                        {credientialsData?.getAllCredentialsByProject.length ? (
                          credientialsData?.getAllCredentialsByProject?.map(
                            (item) => (
                              <tr key={item.email}>
                                <td>{item.email}</td>
                                <td>{item.password}</td>
                                <td>{item.role}</td>
                              </tr>
                            )
                          )
                        ) : (
                          <tr>
                            <td colSpan={3} className="text-center">
                              <b>No data available</b>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </PopoverBody>
              </UncontrolledPopover>
            </>
          )}
        </div>
        {viewGallery()}
        {isDetailExpand ? activeProjectExpandRow() : null}
      </CardBody>
    </Card>
  );
};

export default AssetDetails;
