import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Card, CardTitle, Col, Button, Row } from 'reactstrap';
//
import RBAC from 'hoc/RBAC';
import NoData from 'common/NoData';
import PageLoader from 'common/PageLoader';
import AssetPreviewModal from 'common/AssetPreviewModal';
import AWSFolderAssetsProvider from 'contexts/GetAWSFolderAssets';
import UploadModal from 'pages/Pilot/common/UploadModal';
import {
  PILOT_ASSET_FOLDERS_LISTING,
  PILOT_ASSET_UPDATED,
  PILOT_MARK_COMPLETED_FOLDER,
} from 'pages/Pilot/PilotPortalGraphQL';
import ProjectAssetProvider from 'components/ProjectAssetProvider';
import { ASSET_LISTING_VIDEOS_IMAGES } from 'pages/Pilot/constant';
import { parseToInteger } from 'utils/helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { ROLES } from 'constants/role';
import { authContext } from 'contexts/AuthContext';
import AddNote from '../AddNote';
import MarkComplete from '../MarkComplete';

let fileNamesWithCount = {};

const Videos = ({
  selectProject,
  isProjectAccepted,
  verifyCompletedStatus,
  projectStatus,
  pilotFlagStatusRefetch,
}) => {
  const [assetModal, setAssetModal] = useState(false);
  const [modalData, setModalData] = useState({
    exampleLink: '',
    folderTypes: '',
    videoTitle: '',
  });
  const [fileNameCount, setFileNameCount] = useState({});
  const {
    auth: {
      data: { role },
    },
  } = useContext(authContext);

  const toggleAssetModal = () => setAssetModal(!assetModal);

  const togglePreviewModal = (exampleLink, folderTypes, videoTitle) => {
    setModalData({ exampleLink, folderTypes, videoTitle });
    toggleAssetModal();
  };

  const { subscribeToMore, loading, error, data, refetch } = useQuery(
    PILOT_ASSET_FOLDERS_LISTING,
    {
      variables: {
        projectId: parseToInteger(selectProject),
        folderTypes: ASSET_LISTING_VIDEOS_IMAGES,
        isMarkedCompleted: verifyCompletedStatus ? false : null,
        status: projectStatus,
      },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    const subscribe = subscribeToMore({
      document: PILOT_ASSET_UPDATED,
      updateQuery: (prev, { subscriptionData }) => {
        if (subscriptionData?.data?.pilotAssetUpdated) {
          return refetch();
        }
        return prev;
      },
    });

    return () => subscribe();
  }, []);

  useEffect(() => {
    const subscribe = subscribeToMore({
      document: PILOT_MARK_COMPLETED_FOLDER,
      updateQuery: (prev, { subscriptionData }) => {
        if (subscriptionData?.data?.pilotMarkCompletedFolder) {
          return refetch();
        }
        return prev;
      },
    });

    return () => subscribe();
  }, []);

  if (loading) return <PageLoader />;
  if (error) return <NoData customMessage={error.message} />;

  const { pilotAssetFoldersListing } = data;

  const setCheckMarkColor = (isMarkCompleted) => {
    if (isMarkCompleted) {
      return '#3AB42B';
    }
    return '#ccc';
  };

  const setFilesCount = (assetLocation, count) => {
    setFileNameCount(assetLocation);
    fileNamesWithCount = { ...fileNamesWithCount, [assetLocation]: count };
  };

  const renderList = () => {
    if (!pilotAssetFoldersListing.length)
      return <NoData customMessage="No Videos Available" />;

    const groupedAssets = pilotAssetFoldersListing.reduce((acc, item) => {
      const { folderTypes } = item;
      if (!acc[folderTypes]) {
        acc[folderTypes] = [];
      }
      acc[folderTypes].push(item);
      return acc;
    }, {});

    return Object.keys(groupedAssets).map((folderType) => {
      // Set dynamic heading based on folderTypes
      const heading =
        folderType === 'Video' ? (
          <>Aerial Footage - Minimum 16 Video Shots (:10 - 3:00 min. each)</>
        ) : folderType === 'Image' ? (
          <>Aerial Photos (25 Minimum) - Platinum & Gold</>
        ) : (
          <>Videos</>
        );

      // Render the group
      return (
        <div key={folderType}>
          <h4 className="font-size-18 my-3">
            <b>{heading}</b>
          </h4>
          {/* Dynamic Heading */}
          {groupedAssets[folderType].map(
            ({
              uploadingPath,
              title,
              description,
              exampleLink,
              projectId,
              id,
              isAssetUploaded,
              isMarkedCompleted,
              project,
            }) => {
              return (
                <div key={id}>
                  <AWSFolderAssetsProvider assetLocation={uploadingPath}>
                    <Card
                      body
                      className="mb-3 border-radius-8px border-0 shadow-sm shotlist"
                    >
                      <div className="d-flex">
                        <div className="mr-2">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            color={setCheckMarkColor(
                              isMarkedCompleted,
                              isAssetUploaded
                            )}
                          />
                        </div>
                        <div className="flex-fill">
                          <CardTitle className="font-size-14 font-weight-500 mb-2">
                            {title}
                          </CardTitle>
                          <Row>
                            <Col md={4}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: description,
                                }}
                              />
                              {exampleLink && (
                                <div>
                                  <Button
                                    size="sm"
                                    className="btn btn-link font-size-13 outline-none p-0"
                                    color="link"
                                    onClick={() =>
                                      togglePreviewModal(
                                        exampleLink,
                                        folderType,
                                        title
                                      )
                                    }
                                  >
                                    View Example
                                  </Button>
                                </div>
                              )}
                            </Col>
                            {isProjectAccepted && (
                              <>
                                <Col md={5}>
                                  <ProjectAssetProvider
                                    handleSelectAll={() => {}}
                                    handleSelectFile={() => {}}
                                    isPilotPortal
                                    pilotID={id}
                                    refetchPilotVideoImages={refetch}
                                    isMarkedCompleted={isMarkedCompleted}
                                    setFilesCount={setFilesCount}
                                  />
                                </Col>

                                <Col md={3}>
                                  <div className="text-right">
                                    <RBAC
                                      allowedRoles={[
                                        ROLES.VIDEOMS_PILOT,
                                        ROLES.VIDEOMS_PM,
                                        ROLES.ADMIN,
                                        ROLES.VIDEOMS_QC,
                                      ]}
                                    >
                                      {isAssetUploaded && (
                                        <MarkComplete
                                          projectId={projectId}
                                          isProjectAccepted={isProjectAccepted}
                                          folderId={id}
                                          isMarkCompleted={isMarkedCompleted}
                                          refetchAssets={refetch}
                                          pilotFlagStatusRefetch={
                                            pilotFlagStatusRefetch
                                          }
                                        />
                                      )}
                                    </RBAC>

                                    <RBAC
                                      allowedRoles={[
                                        ROLES.VIDEOMS_PILOT,
                                        ROLES.VIDEOMS_PM,
                                        ROLES.ADMIN,
                                        ROLES.VIDEOMS_QC,
                                      ]}
                                    >
                                      <AddNote
                                        title={title}
                                        folderId={id}
                                        project={project}
                                        refetchAssets={refetch}
                                      />
                                    </RBAC>
                                  </div>
                                  <div className="text-right">
                                    <RBAC allowedRoles={[ROLES.VIDEOMS_PILOT]}>
                                      <UploadModal
                                        upload="Upload"
                                        uploadLocation={uploadingPath}
                                        imageryId={projectId}
                                        pilotID={id}
                                        refetchPilot={refetch}
                                        forPilotPortal
                                        versionSuffix={fileNamesWithCount}
                                        isVideoPortal
                                      />
                                    </RBAC>
                                  </div>
                                </Col>
                              </>
                            )}
                          </Row>
                        </div>
                      </div>
                    </Card>
                  </AWSFolderAssetsProvider>
                </div>
              );
            }
          )}
        </div>
      );
    });
  };

  return (
    <>
      <Row>
        <Col sm="12">{renderList()}</Col>
        <AssetPreviewModal
          assetModal={assetModal}
          toggleAssetModal={toggleAssetModal}
          assetURL={modalData.exampleLink}
          fileType={modalData.folderTypes}
          fileName={modalData.videoTitle}
        />
      </Row>
    </>
  );
};

export default Videos;
