import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { parseToInteger } from 'utils/helper';
import { useMutation, useQuery } from '@apollo/client';
import { Button } from 'reactstrap';
import {
  GET_ASSET_REVIEW_LINK,
  ADD_REVIEW_LINK_APPROVAL,
} from 'pages/PMPortal/components/Projects/ProjectsGraphQL';
import RBAC from 'hoc/RBAC';
import { ROLES } from 'constants/role';
import { authContext } from 'contexts/AuthContext';

const AssetActionItems = ({
  fileName,
  toggleReviewLinkModal,
  toggleAssetActions,
  refetchAssets,
}) => {
  const { projectId, parent } = useParams();

  const {
    auth: {
      data: { role },
    },
  } = useContext(authContext);

  const [addReviewLinkApproval] = useMutation(ADD_REVIEW_LINK_APPROVAL, {
    onCompleted: ({ addReviewLinkApproval }) => {
      const { message } = addReviewLinkApproval;
      if (message.includes('APPROVED')) {
        toggleAssetActions(true);
      }
      refetchAssets();
      toast.success(message);
    },
    onError: ({ message }) => {
      toast.error(message);
    },
  });

  // const reviewLinkApproval = (id, reviewStatus, isNewTemplate) => {
  //   try {
  //     addReviewLinkApproval({
  //       variables: {
  //         projectId: parseToInteger(projectId),
  //         assetId: id,
  //         reviewStatus,
  //         folderName: parent,
  //         isNewTemplate,
  //       },
  //     });
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };
  const ReviewVideoButton = (reviewLink) => {
    return (
      <RBAC
        allowedRoles={[
          ROLES.ADMIN,
          ROLES.VIDEOMS_PM,
          ROLES.VIDEOMS_QC,
          ROLES.VIDEOMS_EDITOR,
        ]}
      >
        <Button
          size="sm"
          className="py-0 px-1 font-size-12 mx-1"
          color="primary"
          outline
          onClick={() => toggleReviewLinkModal(true, reviewLink)}
        >
          Review Video
        </Button>
      </RBAC>
    );
  };
  // const ApproveVideoButton = (id, reviewStatus, isNewTemplate) => {
  //   return (
  //     <RBAC allowedRoles={[ROLES.ADMIN, ROLES.VIDEOMS_PM, ROLES.VIDEOMS_QC]}>
  //       {!reviewStatus && (
  //         <Button
  //           size="sm"
  //           className="py-0 px-1 font-size-12 mx-1"
  //           color="primary"
  //           onClick={() => {
  //             reviewLinkApproval(id, 'APPROVED', isNewTemplate);
  //           }}
  //         >
  //           Approve
  //         </Button>
  //       )}
  //     </RBAC>
  //   );
  // };
  // const DeclineVideoButton = (id, reviewStatus, isNewTemplate) => {
  //   return (
  //     <RBAC allowedRoles={[ROLES.ADMIN, ROLES.VIDEOMS_PM, ROLES.VIDEOMS_QC]}>
  //       {!reviewStatus && (
  //         <Button
  //           size="sm"
  //           className="py-0 px-1 font-size-12 mx-1"
  //           color="danger"
  //           onClick={() => {
  //             reviewLinkApproval(id, 'DECLINED', isNewTemplate);
  //           }}
  //         >
  //           Decline
  //         </Button>
  //       )}
  //     </RBAC>
  //   );
  // };

  const { data, error } = useQuery(GET_ASSET_REVIEW_LINK, {
    variables: {
      projectId: parseToInteger(projectId),
      folderName: parent,
      fileName,
    },
    fetchPolicy: 'network-only',
  });

  if (error) {
    toast.error(error.message);
  }

  if (data && data.getAssetReviewLink) {
    const {
      getAssetReviewLink: { reviewStatus, reviewCurrentStatus },
    } = data;

    return (
      <>
        <div className="flex justify-between mt-2">
          {/* {reviewCurrentStatus === null ||
          (reviewCurrentStatus?.actionTaken === 'APPROVED' &&
            reviewCurrentStatus?.lastActionBy === ROLES.VIDEOMS_QC &&
            role === ROLES.VIDEOMS_PM)
            ? ReviewVideoButton(data?.getAssetReviewLink?.reviewLink)
            : null} */}

          <RBAC
            allowedRoles={[ROLES.ADMIN, ROLES.VIDEOMS_PM, ROLES.VIDEOMS_QC]}
          >
            {role === reviewCurrentStatus?.lastActionBy &&
            reviewCurrentStatus?.actionTaken !== 'DECLINED' ? (
              <span className="py-0 px-1 font-size-12 text-success mx-1">
                Already video reviewed
              </span>
            ) : null}
          </RBAC>

          {(role === ROLES.VIDEOMS_QC || role === ROLES.VIDEOMS_EDITOR) &&
          reviewCurrentStatus?.lastActionBy === ROLES.VIDEOMS_PM &&
          reviewCurrentStatus?.actionTaken !== 'DECLINED' ? (
            <span className="py-0 px-1 font-size-12 text-success mx-1">
              Already approved by PM
            </span>
          ) : null}

          {/* <hr className="mt-2 mb-1" />
          <div>
            {ApproveVideoButton(id, reviewStatus, isNewTemplate)}
            {DeclineVideoButton(id, reviewStatus, isNewTemplate)}
            {reviewStatus === 'APPROVED' && (
              <span className="py-0 px-1 font-size-12  text-primary mx-1">
                Asset Approved
              </span>
            )}
            {reviewStatus === 'DECLINED' && (
              <span className="py-0 px-1 font-size-12 text-danger mx-1">
                Asset Declined
              </span>
            )}
          </div> */}

          <RBAC
            allowedRoles={[
              ROLES.ADMIN,
              ROLES.VIDEOMS_PM,
              ROLES.VIDEOMS_QC,
              ROLES.VIDEOMS_EDITOR,
            ]}
          >
            {reviewStatus === 'DECLINED' && (
              <span className="py-0 px-1 font-size-12 text-danger mx-1">
                {reviewCurrentStatus?.lastActionBy === ROLES.VIDEOMS_QC
                  ? 'Reviewed and revert back to editor for changes by QC'
                  : reviewCurrentStatus?.lastActionBy === ROLES.USER
                  ? 'Reviewed and revert back for changes by USER'
                  : 'Reviewed and revert back to editor for changes by PM'}
              </span>
            )}
          </RBAC>
          {ReviewVideoButton(data?.getAssetReviewLink?.reviewLink)}
          {/* <RBAC allowedRoles={[ROLES.ADMIN, ROLES.VIDEOMS_PM]}>
            {reviewStatus === 'DECLINED' &&
            reviewCurrentStatus?.lastActionBy === ROLES.USER
              ? ReviewVideoButton(data?.getAssetReviewLink?.reviewLink)
              : null}
          </RBAC> */}
        </div>
      </>
    );
  }

  return '';
};

export default AssetActionItems;
