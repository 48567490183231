import { useMutation } from '@apollo/client';
import { authContext } from 'contexts/AuthContext';
import { SEND_MAP_DATA } from 'pages/Search/CrexiSearchGraphQL';
import React, { useContext, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';

const SingleFileUploader = ({ getAssetDetails, openMapBox, refetchData }) => {
  const hiddenFileInput = useRef(null);
  const [fileData, setFileData] = useState(null);
  const [base64String, setBase64String] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setLoading] = useState(false);

  const {
    auth: {
      data: { userId },
    },
  } = useContext(authContext);

  const {
    asset: { id },
  } = getAssetDetails;

  const [sendMapData] = useMutation(SEND_MAP_DATA);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (!file.type.startsWith('image/')) {
        setError('Please upload a valid image file.');
        setFileData(null);
        setBase64String('');
        return;
      }

      setError('');

      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result;
        setBase64String(base64);
      };
      reader.readAsDataURL(file);
      setFileData(file);
    }
  };
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleRemoveFile = () => {
    setFileData(null);
    setBase64String('');
    setError('');
  };

  const handleUploadImage = async () => {
    try {
      setLoading(true);
      const { data } = await sendMapData({
        variables: {
          data: {
            deal_map_screenshot: base64String,
            deal_map_geopoints: [],
          },
          userId,
          assetId: id,
        },
      });

      if (data) {
        setLoading(false);
        refetchData();
        toast.success('Data saved successfully.');
        openMapBox();
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  return (
    <div>
      {!fileData ? (
        <div className="mb-4 p-5 border rounded text-center">
          <Button
            className="button-upload"
            onClick={handleClick}
            color="primary"
            outline
          >
            Click Here to Upload Image
          </Button>
          <input
            type="file"
            onChange={handleFileChange}
            accept="image/*"
            ref={hiddenFileInput}
            className="d-none"
          />

          <p className="mt-2 mb-0">Supported formats: jpg, jpeg, png</p>
        </div>
      ) : null}

      {error && <p style={{ color: 'red' }}>{error}</p>}
      {base64String && (
        <div>
          <img
            src={base64String}
            alt="Uploaded Preview"
            style={{ maxHeight: '300px' }}
            className="w-100"
          />
        </div>
      )}
      {fileData ? (
        <div className="d-flex mt-4">
          <Button
            className="w-100 mr-2"
            color="danger"
            outline
            onClick={handleRemoveFile}
          >
            Remove Image
          </Button>
          <Button
            className="w-100"
            color="primary"
            onClick={handleUploadImage}
            disabled={isLoading}
          >
            {isLoading ? 'Uploading...' : 'Upload Image'}
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default SingleFileUploader;
