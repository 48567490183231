import React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';

const ProjectPlanDetails = ({ projectPlanDetails }) => {
  return (
    <Card className="mt-4 border-radius-8px border-0 shadow overflow-hidden">
      <CardBody>
        <CardTitle tag="h5" className="font-size-16 ">
          PROJECT PLAN DETAILS
        </CardTitle>
        <p className="mb-2">
          Plan Type:<b className="ml-2">{projectPlanDetails?.planType}</b>
        </p>
        <p className="mb-2">
          Plan Price:
          <span className="ml-2">${projectPlanDetails?.planPrice}</span>
        </p>
        <p className="mb-0">
          Project Date:
          <span className="ml-2">{projectPlanDetails?.projectDate}</span>
        </p>
      </CardBody>
    </Card>
  );
};

export default ProjectPlanDetails;
