import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Container,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { find } from 'lodash';
import Scrollbars from 'react-custom-scrollbars-2';

//
import RBAC from 'hoc/RBAC';
import NoData from 'common/NoData';
import Header from 'common/Header';
import { authContext } from 'contexts/AuthContext';
import { ROLES } from 'constants/role';
import PageLoader from 'common/PageLoader';
import { parseToInteger } from 'utils/helper';
import { Helmet } from 'react-helmet';
import ProjectDetail from './components/ProjectDetail';
import {
  PILOT_PROJECT_LISTING,
  PILOT_FOLDER_FLAGS,
  PILOT_EDITOR_INVITATION,
} from './PilotPortalGraphQL';
import ProjectAcceptBox from './components/ProjectAcceptBox';
import { PROJECT_DETAIL, VIDEOS, PHOTOS, CUSTOM_SHOTS } from './constant';
import Videos from './components/Videos';
// import Photos from './components/Photos';
import SortProjectStatus from './common/SortProjectStatus';
import CustomShots from './components/CustomShots';

const PilotDashboard = () => {
  const [activeTab, setActiveTab] = useState(PROJECT_DETAIL);
  const [selectProject, setSelectProject] = useState(0);
  const [currentProject, setCurrentProject] = useState({});
  const [isProjectAccepted, setIsProjectAccepted] = useState(false);
  const [projectStatus, setProjectStatusSorting] = useState('');
  const [listingProjects, setListingProjects] = useState([]);
  const [verifyCompletedStatus, setVerifyCompletedStatus] = useState(false);
  // const [imageOval, setImageOval] = useState(0);
  const [videoOval, setVideoOval] = useState(0);
  const { projectID } = useParams();
  const history = useHistory();

  const {
    auth: {
      data: { role },
    },
  } = useContext(authContext);

  const { subscribeToMore, loading, error, data, refetch } = useQuery(
    PILOT_PROJECT_LISTING,
    {
      variables: { limit: 1000, offset: 0 },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    const subscribe = subscribeToMore({
      document: PILOT_EDITOR_INVITATION,
      updateQuery: (prev, { subscriptionData }) => {
        if (subscriptionData?.data?.userInvited) {
          refetch();
        }
        return prev;
      },
    });

    return () => subscribe();
  }, []);

  const {
    loading: loadingPilotFlag,
    error: pilotFlagError,
    data: pilotFlagData,
    refetch: pilotFlagStatusRefetch,
  } = useQuery(PILOT_FOLDER_FLAGS, {
    variables: {
      projectId: parseToInteger(projectID),
    },
  });

  const setProjectStatus = (project) => {
    const { acceptedByPilot, id } = project;
    setCurrentProject(project);
    setIsProjectAccepted(acceptedByPilot);
    setSelectProject(id);
  };

  useEffect(() => {
    if (data && data.pilotProjectListing) {
      const {
        pilotProjectListing: { projects },
      } = data;

      setListingProjects(projects);

      if (projects && projects.length) {
        const selectedProject = find(projects, (o) => {
          return o.id === parseToInteger(projectID);
        });
        if (selectedProject) {
          const { id } = selectedProject;
          history.push(`/pilot-dashboard/${id}`);
          setProjectStatus(selectedProject);
        } else {
          history.push(`/pilot-dashboard/${projects[0].id}`);
          setProjectStatus(projects[0]);
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (pilotFlagData && pilotFlagData.pilotFolderFlags) {
      const {
        pilotFolderFlags: { videoFolderFlag },
      } = pilotFlagData;
      setVideoOval(videoFolderFlag);
      // setImageOval(imageFolderFlag);
    }
  }, [pilotFlagData]);

  if (loading) return <PageLoader />;
  if (error) return <NoData customMessage={error.message} />;

  const handleSort = (value, acceptStatus, selectedProject) => {
    history.push(`/pilot-dashboard/${value}`);
    setCurrentProject(selectedProject);
    setIsProjectAccepted(acceptStatus);
    setSelectProject(value);
    setVerifyCompletedStatus(false);
    setProjectStatusSorting('');
    setActiveTab(PROJECT_DETAIL);
  };

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setProjectStatusSorting('');
    }
  };

  const toggleVerifyCompleted = (status) => {
    setVerifyCompletedStatus(status);
  };

  const toggleProjectStatus = (status) => {
    setProjectStatusSorting(status);
  };

  const renderCustomShotTab = () => {
    return (
      <NavItem>
        <NavLink
          className={
            activeTab === CUSTOM_SHOTS
              ? 'active cursor-pointer'
              : 'cursor-pointer'
          }
          onClick={() => {
            toggle(CUSTOM_SHOTS);
          }}
        >
          Custom Shots / B-Roll
        </NavLink>
      </NavItem>
    );
  };

  return (
    <div className="pilot-main-wrapper">
      <Helmet defaultTitle="Pilot Portal - VidTech" />
      <Header
        pilotHeader={listingProjects.length > 0}
        handleSort={handleSort}
        selectProject={currentProject}
        projects={listingProjects}
        portalName="Pilot Portal"
      />
      {listingProjects.length > 0 ? (
        <>
          <Container fluid>
            <div className="">
              <div className="bg-grey font-weight-500 pilot-tabs-ul px-3 d-flex overflow-auto align-items-end pilot">
                <Nav tabs className="flex-nowrap align-items-end ml-3">
                  <NavItem>
                    <NavLink
                      className={
                        activeTab === PROJECT_DETAIL
                          ? 'active cursor-pointer'
                          : 'cursor-pointer'
                      }
                      onClick={() => {
                        toggle(PROJECT_DETAIL);
                      }}
                    >
                      Project Detail
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={
                        activeTab === VIDEOS
                          ? 'active cursor-pointer'
                          : 'cursor-pointer'
                      }
                      onClick={() => {
                        toggle(VIDEOS);
                      }}
                    >
                      Shot List
                    </NavLink>
                  </NavItem>
                  {renderCustomShotTab()}
                </Nav>
                {activeTab !== PROJECT_DETAIL && (
                  <FormGroup
                    check
                    className="pilot-mark-complete-checkbox d-flex ml-auto align-items-center"
                  >
                    <SortProjectStatus
                      handleSort={toggleProjectStatus}
                      sortByData={projectStatus}
                    />
                    <RBAC allowedRoles={[ROLES.VIDEOMS_PILOT]}>
                      <Label check className="ml-5">
                        <Input
                          type="checkbox"
                          id="topping"
                          name="topping"
                          onChange={(e) =>
                            toggleVerifyCompleted(e.target.checked)
                          }
                          checked={verifyCompletedStatus}
                        />
                        <span className="align-middle d-inline-block line-height-23">
                          Verify Completed
                        </span>
                      </Label>
                    </RBAC>
                  </FormGroup>
                )}
              </div>
              <Scrollbars
                autoHide
                className="row"
                autoHideTimeout={1000}
                autoHideDuration={200}
                style={{
                  width: '100%',
                  height: `calc(100vh - ${
                    !isProjectAccepted ? '210' : '115'
                  }px)`,
                  margin: '0px',
                }}
              >
                <TabContent className="px-md-3" activeTab={activeTab}>
                  <TabPane tabId={PROJECT_DETAIL}>
                    {activeTab === PROJECT_DETAIL && (
                      <ProjectDetail
                        selectProject={selectProject}
                        isProjectAccepted={isProjectAccepted}
                        currentProject={currentProject}
                      />
                    )}
                  </TabPane>
                  <TabPane tabId={VIDEOS}>
                    {activeTab === VIDEOS && (
                      <Videos
                        selectProject={selectProject}
                        isProjectAccepted={isProjectAccepted}
                        verifyCompletedStatus={verifyCompletedStatus}
                        projectStatus={projectStatus}
                        pilotFlagStatusRefetch={pilotFlagStatusRefetch}
                      />
                    )}
                  </TabPane>
                  {/* <TabPane tabId={PHOTOS}>
                  {activeTab === PHOTOS && (
                    <Photos
                      selectProject={selectProject}
                      isProjectAccepted={isProjectAccepted}
                      verifyCompletedStatus={verifyCompletedStatus}
                      projectStatus={projectStatus}
                      pilotFlagStatusRefetch={pilotFlagStatusRefetch}
                    />
                  )}
                </TabPane> */}
                  <TabPane tabId={CUSTOM_SHOTS}>
                    {activeTab === CUSTOM_SHOTS && (
                      <CustomShots
                        selectProject={selectProject}
                        isProjectAccepted={isProjectAccepted}
                        verifyCompletedStatus={verifyCompletedStatus}
                        projectStatus={projectStatus}
                        pilotFlagStatusRefetch={pilotFlagStatusRefetch}
                      />
                    )}
                  </TabPane>
                </TabContent>
              </Scrollbars>
            </div>
          </Container>

          <RBAC allowedRoles={[ROLES.VIDEOMS_PILOT]}>
            <ProjectAcceptBox
              selectProject={selectProject}
              isProjectAccepted={isProjectAccepted}
              refetch={refetch}
            />
          </RBAC>
        </>
      ) : (
        <Container fluid>
          <NoData customMessage="Hi, It seems you haven't been assigned any project." />
        </Container>
      )}
    </div>
  );
};

export default PilotDashboard;
