import React, { useRef, useEffect, useState, useContext } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { useMutation } from '@apollo/client';
import PopupForm from 'pages/Search/components/PropertyDetails/components/CallToAction/components/ProjectInstructionModal';
import { authContext } from 'contexts/AuthContext';
import { SEND_MAP_DATA } from 'pages/Search/CrexiSearchGraphQL';
import { polygonStyle } from 'utils/helper';
import { useSelector } from 'react-redux';
import { ADD_ASSET_BROKER } from 'pages/Search/components/PropertyDetails/components/AuctionBrokerDetails/graphQl';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;

const Map = ({ getAssetDetails, openMapBox, refetchData }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [draw, setDraw] = useState(null);
  const [modal, setModal] = useState(false);
  const [lng, setLng] = useState(-70.9);
  const [lat, setLat] = useState(42.35);
  const [zoom, setZoom] = useState(12);
  const toggle = () => setModal(!modal);
  const [formData, setFormData] = useState(null);
  const [isCancel, setCancel] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const isQuote = false;

  const { plan } = useSelector((state) => state.salesPortal);

  const {
    auth: {
      data: { userId },
    },
  } = useContext(authContext);

  const {
    asset: { id, address },
  } = getAssetDetails;

  useEffect(() => {
    if (map.current) return; // initialize map only once
    const initializeMap = () => {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/satellite-v9',
        center: [lng, lat],
        zoom,
        preserveDrawingBuffer: true,
      });

      const drawControl = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          trash: true,
        },
        styles: polygonStyle,
      });
      const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl,
        marker: true,
      });

      map.current.addControl(geocoder, 'top-right');
      map.current.addControl(drawControl, 'top-left');
      map.current.addControl(new mapboxgl.NavigationControl(), 'top-left');
      setDraw(drawControl);

      if (address) {
        geocoder.query(address);
      }
    };
    initializeMap();
  });

  const [sendMapData] = useMutation(SEND_MAP_DATA);
  const [addBroker] = useMutation(ADD_ASSET_BROKER);

  const updateFormData = (data) => {
    setFormData(data);
    toggle();
  };

  const saveData = async () => {
    const polygonData = draw.getAll();

    if (!polygonData || !polygonData?.features?.length) {
      toast.error('Please draw a polygon first.');
    } else {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = map.current.getCanvas().width;
      canvas.height = map.current.getCanvas().height;

      ctx.drawImage(map.current.getCanvas(), 0, 0);
      const dataURL = canvas.toDataURL('image/jpeg');

      if (!formData && !isCancel) {
        toggle();
      } else {
        try {
          setLoading(true);

          await addBroker({
            variables: {
              userInstructions: {
                mapping_question_1: isCancel
                  ? ''
                  : formData?.mapping_question_1,
                mapping_question_2: isCancel
                  ? ''
                  : formData?.mapping_question_2,
                mapping_question_3: isCancel
                  ? ''
                  : formData?.mapping_question_3,
              },
              assetId: id,
              status: 'Inprogress',
              projectPlan: plan?.type ? plan.type : 'PLATINUM',
            },
          });

          const { data } = await sendMapData({
            variables: {
              data: {
                deal_map_screenshot: dataURL,
                deal_map_geopoints:
                  polygonData.features[0].geometry.coordinates,
                deal_map_instructions: {
                  mapping_question_1: isCancel
                    ? ''
                    : formData.mapping_question_1,
                  mapping_question_2: isCancel
                    ? ''
                    : formData.mapping_question_2,
                  mapping_question_3: isCancel
                    ? ''
                    : formData.mapping_question_3,
                },
              },
              userId,
              assetId: id,
            },
          });

          if (data) {
            setLoading(false);
            setDisabled(true);
            refetchData();
            toast.success('Data saved successfully.');
            openMapBox();
          }
        } catch (error) {
          setLoading(false);
          toast.error(error.message);
        }
      }
    }
  };

  return (
    <div>
      <PopupForm
        isOpen={modal}
        toggle={toggle}
        handleSubmit={updateFormData}
        setCancel={setCancel}
      />
      <div className="map-container" ref={mapContainer} />
      {!isDisabled && (
        <div className="position-absolute mapbox-button">
          <Button
            color="secondary"
            onClick={saveData}
            className="px-3"
            disabled={isLoading || isQuote}
          >
            {isLoading
              ? 'Saving...'
              : formData || isCancel
              ? 'Save Map Changes'
              : 'Submit'}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Map;
